
















import Vue, { PropType } from 'vue';
import { TTile } from '@/containers/TilesContainer/types';
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue';

export default Vue.extend({
  name: 'TileItem',

  components: {
    SvgIcon,
  },

  props: {
    tile: {
      required: true,
      type: Object as PropType<TTile>,
    },
  },

  methods: {
    clickTile () {
      this.$emit('clickTile');
    },
  },
});
