
























































































import Vue, { PropOptions } from 'vue';

import NewsWidget from '~/components/NewsWidget/NewsWidget.vue';
import WidgetsRotator from '~/components/WidgetsRotator.vue';
import CommentsWidgetContainer from '~/containers/CommentsWidgetContainer.vue';
import ArticleListContainer from '~/containers/ArticleListContainer/ArticleListContainer.vue';
import HtmlWidgetContainer from '~/containers/HtmlWidgetContainer/HtmlWidgetContainer.vue';
import TaxonomyPostsContainer from '~/containers/TaxonomyPostsContainer/TaxonomyPostsContainer.vue';
import PostCollectionContainer from '~/containers/PostCollectionContainer/PostCollectionContainer.vue';
import RecipesWidgetContainer from '~/containers/RecipesWidgetContainer.vue';
import QuickPollContainer from '~/containers/QuickPollContainer/QuickPollContainer.vue';
import SportsWidget from '~/components/Sports/SportsWidget.vue';
import RecommendationBlock from '~/components/RecommendationBlock/RecommendationBlock.vue';
import BlockPromise from '~/components/BlockPromise/BlockPromise.vue';
import TilesRubrics from '~/components/TilesRubrics/TilesRubrics.vue';
import HealthButtonCollection from '@/containers/HealthButtonCollection/HealthButtonCollection.vue';

import { formattingItemSection } from '~/utils/formattingData';

export default Vue.extend({
  name: 'WidgetsRenderer',
  components: {
    CommentsWidgetContainer,
    ArticleListContainer,
    WidgetsRotator,
    HtmlWidgetContainer,
    TaxonomyPostsContainer,
    PostCollectionContainer,
    RecipesWidgetContainer,
    QuickPollContainer,
    NewsWidget,
    SportsWidget,
    RecommendationBlock,
    BlockPromise,
    TilesRubrics,
    HealthButtonCollection,
  },
  props: {
    widget: {
      required: true,
      type: Object,
    } as PropOptions<TWidget>,
    inSidebar: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    recipesWidgetDisplayType: {
      type: String,
      default: 'default',
    } as PropOptions<'default' | 'big-tiles'>,
    recipesWidgetEventCategory: {
      type: String,
      required: true,
    } as PropOptions<string>,
  },
  methods: {
    onRecipesWidgetShowMore (data: Record<string, string>): void {
      this.$emit('recipes-widget-show-more', data);
    },

    onRecipesWidgetClickRecipe (data: Record<string, string>): void {
      this.$emit('recipes-widget-click-recipe', data);
    },

    getFormattingArticles (articles: ISinglePostServerResponse[]): ISingleArticleServerResponse[] {
      return articles.map((article: ISinglePostServerResponse) => formattingItemSection(article));
    },
  },
});
