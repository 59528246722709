














import Vue from 'vue';
import SearchInput from './SearchInput.vue';

export default Vue.extend({
  name: 'TopHeaderSearch',

  components: {
    SearchInput,
  },

  methods: {
    handleSearch (query: string) {
      this.$emit('search', query);
      this.$emit('click-on-close');
    },

    clickClose () {
      this.$emit('click-on-close');

      this.$sendYandexMetrika({
        level1: 'Событие_Поиск',
        level6: 'Закрытие поиска',
      });
    },
  },
});
